<template>
  <div class="manage">
    <h5header :name="$t('manage.desc15')" :right="false" />
    <div class="manage-con" v-if="settup == 1">
      <div class="manage-link" v-if="type == 0">
        <span @click="$router.go(-1)">{{ $t("new.desc1") }}</span>
        <img src="../../public/images/new/righticon1.png" alt="" />
        <span @click="$router.go(-1)">{{ $t("node.desc58") }}</span>
        <img src="../../public/images/new/righticon1.png" alt="" /><span>{{
          $t("manage.desc15")
        }}</span>
      </div>
      <div class="manage-link" v-else>
        <span @click="$router.go(-1)">{{ $t("new.desc3") }} </span>
        <img src="../../public/images/new/righticon1.png" alt="" /><span>{{
          $t("manage.desc15")
        }}</span>
      </div>
      <div class="modal-con">
        <div class="manage-title">{{ $t("node.desc59") }}</div>
        <div class="manage-name">{{ $t("manage.desc21") }}</div>
        <div class="manage-input">
          <input
            type="text"
            :placeholder="$t('manage.desc22')"
            v-model="name"
            maxlength="20"
          />
        </div>
        <div class="manage-len">{{ name.length || 0 }}/20</div>
        <div class="manage-name">{{ $t("manage.desc23") }}</div>
        <div class="manage-input">
          <textarea
            :placeholder="$t('manage.desc24')"
            cols="30"
            rows="3"
            v-model="description"
            maxlength="200"
          ></textarea>
        </div>
        <div class="manage-len">{{ name.length || 0 }}/200</div>
        <div class="upload-info flex">
          <div class="img" v-if="icon">
            <star-img
              ref="starOldIcon"
              :sphereImg="icon"
              :starWidth="ismobile ? 100 : 190"
              :starHeight="ismobile ? 100 : 190"
            />
            <img :src="icon" ref="invitecon" alt="" class="uploadimg" />
          </div>
          <div class="upload-rules">
            <div class="rules-name flex">
              <img src="../../public/images/new/assetsicon.png" alt="" />
              {{ $t("manage.desc25") }}
            </div>
            <div class="rules-desc">{{ $t("manage.desc26") }}</div>
            <div class="rules-desc">{{ $t("manage.desc27") }}</div>
            <div class="rules-desc">{{ $t("manage.desc28") }}</div>
            <div class="rules-btn">
              {{ $t("manage.desc34") }}:
              <span @click="handlerDown">{{ $t("manage.desc29") }}</span>
            </div>
            <div class="rules-btn1 flex">
              <div class="flexcenter" v-loading="loading">
                <input
                  type="file"
                  @change="uploadImg"
                  ref="uploadimg"
                  v-if="!loading"
                />
                {{ $t("manage.desc30") }}
              </div>
            </div>
          </div>
        </div>
        <div class="manage-tips1">{{ $t("manage.desc32") }}</div>
        <div class="manage-btn flex">
          <div class="flexcenter" @click="$router.go(-1)">
            {{ $t("manage.desc35") }}
          </div>
          <div class="flexcenter" @click="handlerNext">
            {{ $t("manage.desc36") }}
          </div>
        </div>
      </div>
    </div>
    <div class="manage-con" v-if="settup == 2">
      <div class="manage-link">
        <p @click="settup = 1">
          <img
            src="../../public/images/new/lefticon2.png"
            alt=""
            class="cursor"
          /><span>{{ $t("manage.desc40") }}</span>
        </p>
      </div>
      <div class="next-con">
        <div class="next-name">{{ $t("manage.desc41") }}</div>
        <div class="next-img" v-if="icon">
          <star-img
            :sphereImg="icon"
            :starWidth="ismobile ? 100 : 220"
            :starHeight="ismobile ? 100 : 220"
          />
        </div>
        <div class="next-title">{{ $t("manage.desc42") }}</div>
        <div class="next-subname">{{ name }}</div>
        <div class="next-tips">
          {{ $t("manage.desc23") }}：{{ description }}
        </div>
        <div class="next-tips1">{{ $t("manage.desc20", { n1: days }) }}</div>
        <div
          class="next-btn flexcenter"
          v-loading="loading1"
          @click="handlerSubmit"
        >
          {{ $t("manage.desc43") }}
        </div>
      </div>
    </div>
    <div class="modal-load" v-if="shownext">
      <div class="load-con">
        <div class="load-name">{{ $t("manage.desc39") }}</div>
        <div class="load-img" v-if="icon">
          <star-img
            :sphereImg="icon"
            :starWidth="ismobile ? 100 : 440"
            :starHeight="ismobile ? 100 : 440"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import h5header from "@/componets/h5header.vue";
import html2canvas from "html2canvas";
import starImg from "@/componets/starImg.vue";
import { mapState } from "vuex";
export default {
  components: {
    h5header,
    starImg,
  },
  data() {
    return {
      name: "",
      description: "",
      icon: "",
      loading: false,
      settup: 1,
      shownext: false,
      loading1: false,
      type: 0,
      ismobile: "",
      days: 3,
    };
  },
  computed: {
    ...mapState(["modifyData"]),
  },
  mounted() {
    this.ismobile = localStorage.getItem("ismobile") == 0 ? false : true;
    if (this.$route.query.type != undefined) {
      this.type = Number(this.$route.query.type);
    }
    this.handleInit();
  },
  methods: {
    handleInit() {
      const { name, description, icon, days } = this.modifyData;
      this.name = name;
      this.description = description;
      this.icon = icon;
      this.days = days;
    },

    handlerDown() {
      html2canvas(this.$refs.invitecon, {
        useCORS: true,
      }).then((canvas) => {
        let dataURL = canvas.toDataURL("image/png");
        if (dataURL !== "") {
          const a = document.createElement("a");
          a.href = dataURL;
          a.download = this.$t("manage.desc34");
          a.click();
        }
      });
    },
    handlerNext() {
      if (this.name == "") {
        this.$message.error(this.$t("manage.desc37"));
        return;
      }
      if (this.description == "") {
        this.$message.error(this.$t("manage.desc38"));
        return;
      }
      if (this.type === 1 && !this.icon) {
        this.$message.error(this.$t("manage.desc55"));
        return;
      }
      this.shownext = true;
      setTimeout(() => {
        this.shownext = false;
        this.settup = 2;
      }, 2000);
    },
    handlerSubmit() {
      if(this.loading1){
        return;
      }
      this.loading1 = true;
      this.$post(this.URL.node.confirm, {
        planetId: this.$route.query.id,
        name: this.name,
        icon: this.icon,
        description: this.description,
      }).then((res) => {
       
        if (res.code == 0) {
          this.$message.success(this.$t("manage.desc44"));
          setTimeout(() => {
            this.$router.push("/manage");
          }, 2000);
        } else {
          this.loading1 = false;
          this.$message.error(res.message);
        }
      });
    },
    uploadImg(e) {
      let file = this.$refs.uploadimg.files[0],
        fileMaxSize = 1024 * 1024 * 4;
      if (file.size > fileMaxSize) {
        this.$message.error(this.$t("manage.desc27"));
        return;
      }
      this.loading = true;
      this.$post(
        this.URL.node.upload,
        {
          file: file,
        },
        { isUpload: true }
      ).then((res) => {
        this.loading = false;
        if (res.code == 0) {
          this.icon = res.data.url;
          this.$refs.starOldIcon.replaceTexture(this.icon);
          this.$forceUpdate();
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.manage {
  width: 100%;
  padding: 136px 0 80px;
  background: url("../../public/images/new/landbg.png") center no-repeat;
  background-size: 100% 100%;

  .manage-con {
    width: 580px;
    margin: 0 auto;
    width: 580px;
    position: relative;

    .manage-link {
      display: flex;
      margin-bottom: 20px;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      line-height: 14px;

      img {
        width: 12px;
        height: 14px;
        margin: 0 8px;
      }

      span {
        cursor: pointer;

        &:last-child {
          color: #fff;
          cursor: inherit;
        }
      }

      p {
        cursor: pointer;
      }
    }

    .modal-con {
      padding: 32px;
      background: rgba(24, 24, 24, 0.8);
      border-radius: 12px;

      .manage-title {
        padding-bottom: 12px;
        font-size: 20px;
        color: #ffffff;
      }
    }

    .manage-tips {
      background: #232323;
      border-radius: 4px;
      padding: 16px;
      font-size: 14px;
      color: #e15757;
    }

    .manage-name {
      padding: 20px 0 12px;
      font-size: 16px;
      color: #ffffff;
    }
    .manage-len{
      margin-top: 12px;
      text-align: right;
      font-size: 16px;
      color: #fff;
    }
    .manage-input {
      padding: 19px 16px;
      border: 1px solid #393939;
      border-radius: 4px;
      box-sizing: border-box;

      input {
        width: 100%;
        height: 18px;
        font-size: 14px;
        color: #fff;

        &::placeholder {
          color: #5f5f5f;
        }
      }

      textarea {
        width: 100%;
        font-size: 14px;
        color: #fff;

        &::placeholder {
          color: #5f5f5f;
        }
      }
    }

    .upload-info {
      margin: 24px 0 0;

      .img {
        position: relative;
        flex: 0 0 140px;
        width: 140px;
        height: 140px;
        margin-right: 20px;
        object-fit: cover;
        overflow: hidden;

        .uploadimg {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 20px;
          height: 20px;
          transform: translate(-50%, -50%);
          border-radius: 50%;
        }
      }

      .upload-rules {
        flex: 1;
        overflow: hidden;

        .rules-name {
          margin-bottom: 12px;
          font-size: 18px;
          color: #ffffff;
          line-height: 18px;

          img {
            width: 14px;
            height: 14px;
            margin-right: 8px;
            margin-top: 2px;
          }
        }

        .rules-desc {
          font-size: 14px;
          color: #a8a8a8;
        }

        .rules-btn {
          margin: 12px 0 20px;
          font-size: 14px;
          color: #ffffff;

          span {
            color: #c9fa5b;
            cursor: pointer;
          }
        }

        .rules-btn1 {
          position: relative;

          div {
            position: relative;
            flex: 0 0 160px;
            height: 40px;
            background: url("../../public/images/new/btnbg3.png") center
              no-repeat;
            background-size: 100% 100%;
            font-size: 14px;
            color: #c9fa5b;
            cursor: pointer;

            input {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 40px;
              opacity: 0;
              z-index: 5;
              cursor: pointer;
            }
          }
        }
      }
    }

    .manage-tips1 {
      padding: 16px;
      background: #1c1c1c;
      margin: 20px 0 24px;
      font-size: 14px;
      color: #e15757;
      border-radius: 8px;
    }

    .manage-btn {
      justify-content: space-between;

      div {
        flex: 0 0 248px;
        height: 52px;
        background: url("../../public/images/new/btnbg17.png") center no-repeat;
        background-size: 100% 100%;
        font-size: 16px;
        color: #ffffff;
        cursor: pointer;

        &:last-child {
          background: url("../../public/images/new/btnbg18.png") center
            no-repeat;
          background-size: 100% 100%;
          color: #000000;
        }
      }
    }

    .next-con {
      padding: 40px;
      background: rgba(24, 24, 24, 0.8);
      border-radius: 12px;

      .next-name {
        font-size: 24px;
        color: #ffffff;
        text-align: center;
      }

      .next-img {
        margin: 32px auto 12px;
        text-align: center;
        width: 200px;
        height: 200px;
        overflow: hidden;

        img {
          width: 200px;
          height: 200px;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      .next-title {
        font-size: 20px;
        color: rgba(255, 255, 255, 0.6);
        text-align: center;
      }

      .next-subname {
        margin: 16px 0 32px;
        text-align: center;
        font-size: 32px;
        color: #c9fa5b;
      }

      .next-tips {
        font-size: 18px;
        color: #ffffff;
        line-height: 22px;
      }

      .next-tips1 {
        margin: 16px 0 24px;
        padding: 16px;
        border-radius: 8px;
        font-size: 14px;
        color: #e15757;
        background: #1c1c1c;
      }

      .next-btn {
        height: 52px;
        background: url("../../public/images/new/btnbg19.png") center no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
        font-size: 16px;
        color: #000000;
      }
    }
  }
}

.modal-load {
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;

  .load-con {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    transform: translate(-50%, -50%);

    .load-name {
      margin-bottom: 40px;
      font-size: 32px;
      color: #c9fa5b;
      text-align: center;
    }

    .load-img {
      width: 400px;
      height: 400px;
      // img {
      //     width: 400px;
      //     height: 400px;
      //     border-radius: 50%;
      //     object-fit: cover;
      //     animation-name: ball;
      //     animation-duration: 2s;
      //     animation-timing-function: linear;
      //     animation-iteration-count: infinite;
      // }
    }
  }
}

@keyframes ball {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 767px) {
  .manage {
    padding: 56px 0;
    background: #000000;

    .manage-con {
      width: calc(100vw - 8px);

      .manage-link {
        display: none;
      }

      .modal-con {
        padding: 20px 16px;

        .manage-title {
          font-size: 18px;
          padding-bottom: 4px;
        }

        .manage-name {
          padding: 16px 0 10px;
          font-size: 14px;
        }

        .manage-input {
          padding: 12px;

          input {
            font-size: 14px;
          }
        }

        .upload-info {
          flex-wrap: wrap;

          .img {
            flex: 0 0 140px;
            width: 140px;
            height: 140px;
            margin: 0 auto;
          }

          .upload-rules {
            flex: 0 0 100%;

            .rules-name {
              font-size: 16px;
            }

            .rules-desc {
              font-size: 13px;
            }

            .rules-btn {
              margin: 12px 0 16px;
            }
          }
        }

        .manage-tips1 {
          padding: 12px;
          font-size: 13px;
        }

        .manage-btn {
          div {
            flex: 0 0 49%;
            height: 46px;
          }
        }
      }

      .next-con {
        padding: 20px 16px;

        .next-name {
          font-size: 18px;
        }

        .next-img {
          margin: 20px auto 16px;
        }

        .next-title {
          font-size: 16px;
        }

        .next-subname {
          margin: 12px 0 32px;
          font-size: 24px;
        }

        .next-tips {
          font-size: 16px;
        }

        .next-tips1 {
          margin: 16px 0 20px;
          padding: 12px;
          font-size: 13px;
        }

        .next-btn {
          height: 46px;
        }
      }
    }
  }

  .modal-load {
    .load-con {
      .load-name {
        margin-bottom: 20px;
        font-size: 20px;
      }

      .load-img {
        text-align: center;

        img {
          width: 240px;
          height: 240px;
        }
      }
    }
  }
}
</style>
